.mass-delivery {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  input {
    margin-top: 5px;
    width: 250px;
    padding-left: 20px;
    padding-block: 5px;
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-color: white;
    border: none !important;
    &:focus {
      outline: none; /* Varsayılan odak çerçevesini gizlemek için */
      border-color: blue;
      box-shadow: 0 0 5px blue;
    }
  }

  .remove-button {
    margin-left: 10px;
    padding: 5px;
    background-color: transparent;
    border: none;
    color: #ff0000;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .remove-button:hover {
    color: #ffffff;
    border: none;
    border-radius: 5px;
    background-color: #ff0000;
  }

  .button-71 {
    margin-left: 25px;
    background-color: #0078d0;
    border: 0;
    border-radius: 56px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu,
      "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 5px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset,
      rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 2px 24px;
    }
  }
}
