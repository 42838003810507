.report {
  .date-picker {
    width: 150px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
    font-size: 14px;
    margin-top: 10px;
  }

  .select-box {
    width: 200px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
    font-size: 14px;
  }

  .select-box .select__control {
    background-color: white;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .select-box .select__single-value {
    color: black;
  }

  .select-box .select__indicator-separator {
    display: none;
  }

  .select-box .select__menu {
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .select-box .select__option--is-focused {
    background-color: #f8f9fa;
  }

  .select-box .select__option--is-selected {
    background-color: #f8f9fa;
  }
}
