.mass-entry {
  min-width: 1145px;
  .basic-single {
    width: 300px;
  }

  .button-71 {
    background-color: #0078d0;
    border: 0;
    border-radius: 56px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu,
      "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset,
      rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 10px 48px;
    }
  }
}

.form-content {
  height: 50px;
  min-width: 1100px;
  input {
    margin-top: 10px;
    width: 190px;
    padding-inline: 10px;
    padding-block: 6.5px;
    border-color: #dbdbdb;
    border-radius: 5px;
    border-width: 1px;
    // border-radius: 5px;
    // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    //   rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    // border-color: white;
    // border: none !important;
    // &:focus {
    //   outline: none; /* Varsayılan odak çerçevesini gizlemek için */
    //   border-color: blue;
    //   box-shadow: 0 0 5px blue;
    // }
  }
  // select {
  //   margin-top: 5px;
  //   width: 190px;
  //   padding-left: 10px;
  //   padding-block: 5px;
  //   border-radius: 5px;
  //   box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
  //     rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  //   border-color: white;
  //   border: none !important;
  //   &:focus {
  //     outline: none; /* Varsayılan odak çerçevesini gizlemek için */
  //     border-color: blue;
  //     box-shadow: 0 0 5px blue;
  //   }
  // }
}

.basic-single2 {
  width: 250px;
}
