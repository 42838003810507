.app {
  align-items: center;
  justify-content: center;
}
.center {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* max-width: 1300px; */
  /* min-width: 500px; */
}

@media screen and (max-width: 800px) {
  .center {
    width: 100%;
  }
}
