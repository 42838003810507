table {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
  font-size: 14px;
  margin-bottom: 50px;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media (max-width: 600px) {
  th,
  td {
    padding: 6px;
  }
}

.select__menu {
  background-color: white !important;
}

.css-1nmdiq5-menu {
  background-color: white !important;
}

.filter {
  width: 180px;
  margin-top: 5px;
  border-radius: 5px;
  border-width: 0.5px;
  border-color: rgb(224, 224, 224);
  padding-inline: 5px;
  padding-block: 3px;
}

.basic-single {
  width: 200px;
  margin-top: 10px;
}

.clear-btn {
  background-color: #f56161;
  width: 120px;
  border: none;
  border-radius: 5px;
}

.home {
  .date-picker {
    width: 150px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
    font-size: 14px;
    margin-top: 10px;
  }

  .select-box {
    width: 200px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
    font-size: 14px;
  }

  .select-box .select__control {
    background-color: white;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .select-box .select__single-value {
    color: black;
  }

  .select-box .select__indicator-separator {
    display: none;
  }

  .select-box .select__menu {
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .select-box .select__option--is-focused {
    background-color: #f8f9fa;
  }

  .select-box .select__option--is-selected {
    background-color: #f8f9fa;
  }
}
