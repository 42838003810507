.header-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
  color: white !important;
}

@media screen and (max-width: 992px) {
  .header-link {
    margin-bottom: 5px;
  }
}
.navbar {
  background-color: rgb(26, 28, 39) !important;
  --bs-navbar-padding-y: 0rem;
  margin-bottom: 60px;
}

.container-fluid {
  margin-block: 0px !important;
}

.navbar-toggler {
  color: white !important;
  background-color: white;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
}
