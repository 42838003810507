.login-container {
  background-image: url("../../assets/images/logistic.jpg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin-top: 20px;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.login-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #45a049;
}
